// 
// Splider-slider.scss
// Theme Component

// Splider thumbnail
.primary-slider [data-glightbox] { 
  cursor:zoom-in;
}
.splide-thumb{
  margin-top: 10px;
}
.splide-thumb>.splide__track>.splide__list>.splide__slide.is-active {
  box-shadow: 0px 0px 0px 5px inset $primary;
  border: none;
  
}
.splide--nav>.splide__track>.splide__list>.splide__slide {
  border-radius: 5px;
  border:none;
}
