//
// form-check.scss
// Extended from Bootstrap
//

//
// Bootstrap Overrides
//

// Checkbox style
.form-check .form-check-input{
  cursor: pointer;
}