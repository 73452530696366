// 
// tiny-slider.scss
// Theme Component

.tns-outer{
  position: relative;
}

.tiny-slider{
  .item{
    position: relative;
  }
}

//
// Slider arrow
//
.tns-controls{
  [data-controls] {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 50px;
    height: 50px;
    line-height: 50px;
    border-radius: $border-radius;
    display: block;
    z-index: 9;
    text-align: center;
    transition: $transition-base;
    background: rgba($primary, .1);
    color: $primary;
    border: none;
    padding: 0;
    margin: 0 10px;
    &:hover{
      background-color: $primary;
      color: $white;
    }
    i{
      line-height: inherit;
    }
  }
}

.tns-controls [data-controls='prev'] {
  left: 0;
}
.tns-controls [data-controls='next'] {
  right: 0;
}

/* rtl:raw:
.tiny-slider .tns-controls [data-controls]{
  transform: scaleX(-1) translateY(-50%);
}
*/

// Arrow dark
.arrow-dark{
  [data-controls] {
    background: $dark;
    color: $white;
  }
}

// Arrow round
.arrow-round{
  [data-controls] {
    border-radius: 50%;
  }
}

// Arrow only
.arrow-only{
  [data-controls] {
    background: transparent;
    &:hover{
      background: transparent;
      color: $gray-800;
    }
  }
}

// Arrow hover
.arrow-hover{
  overflow: hidden;
  [data-controls='prev']{
    left: -60px;
  }
  [data-controls='next']{
    right: -60px;
  }
  &:hover{
    [data-controls='prev']{
    left: 0px;
    }
    [data-controls='next']{
      right: 0px;
    }
  }
}

// Arrow md none
@include media-breakpoint-down(md) {
  .arrow-md-none [data-controls] {
    display: none;
  }
}

// 
// Slider dots
// 
.tns-nav{
  position: absolute;
  top: 100%;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 10;
  width: auto;
  text-align: center;
  white-space: nowrap;
  [data-nav] {
    position: relative;
    display: inline-block;
    margin: 20px 4px;
    padding: 0;
    width: 14px;
    height: 14px;
    border-radius: 50%;
    overflow: hidden;
    background-color: transparent;
    box-shadow: inset 0 0 0 2px $gray-500;
    transition: $transition-base;
    border: 0;
    &:after{
      border-radius: 50%;
      overflow: hidden;
      content: '';
      position: absolute;
      bottom: 0;
      height: 0;
      left: 0;
      width: 100%;
      background-color: $gray-500;
      box-shadow: 0 0 1px $gray-500;
      -webkit-transition: height $transition-base;
      transition: height $transition-base;
    }
  }
  .tns-nav-active{
    &:after{
      height: 100%;
    }
  }
}

// Dots primary
.dots-primary .tns-nav{
  [data-nav] {
    box-shadow: inset 0 0 0 2px $primary;
    transition: $transition-base;
    &:after{
      background-color: $primary;
      box-shadow: 0 0 1px $primary;
    }
  }
}

// Dots white
.dots-white .tns-nav{
  [data-nav] {
    box-shadow: inset 0 0 0 2px $white;
    transition: $transition-base;
    &:after{
      background-color: $white;
      box-shadow: 0 0 1px $white;
    }
  }
}

// Dots dark
.dots-dark .tns-nav{
  [data-nav] {
    box-shadow: inset 0 0 0 2px $dark;
    transition: $transition-base;
    &:after{
      background-color: $dark;
      box-shadow: 0 0 1px $dark;
    }
  }
}

// Dots creative
.dots-creative .tns-nav [data-nav] {
  background: $gray-300;
  border: none;
  width: 10px;
  height: 10px;
  border-radius: 10px;
  box-shadow: none;
}
.dots-creative .tns-nav [data-nav]:after {
  content: none;
}
.dots-creative .tns-nav .tns-nav-active {
  background: $primary;
  width: 24px;
}