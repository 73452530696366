// 
// nouislider.scss
// Theme Component

.noUi-connect {
  background: $primary;
}
.noUi-target {
  background: rgba($primary, 0.1);
  border-radius: 4px;
  border: none;
  box-shadow: none;
}
.noUi-horizontal {
  height: 5px;
  .noUi-handle {
    width: 20px;
    height: 20px;
    right: -17px;
    top: -7px;
    cursor: e-resize;
    border-radius: 100%;
    border: none;
    background: $primary;
    box-shadow: none;

    &:before, &:after {
      display: none;
    }
  }
} 
.noui-wrapper input[class*='input-with-'] {
  background-color: transparent;
  border: none;
  color: $primary;
  width: 70px;
}

// Input style for price range width
.noui-wrapper input[class*='input-with-']{
  width:100px;
}