//
// badge.scss
// Extended from Bootstrap
//

//
// Bootstrap Overrides
//

// Style for badge
a.badge{
  color: $white;
}