// 
// choices.scss
// Vendor css override and new css added


.choices{
  
  .choices__inner{
    background-color: $input-bg;
    color: $input-color;
    border: $input-border-width solid $input-border-color;
    line-height: $input-line-height;  
    border-radius: $border-radius;
    min-height: auto;
    font-size: 0.9375rem;
    padding: $form-select-padding-y $form-select-indicator-padding $form-select-padding-y $form-select-padding-x !important;
  }
  .choices__list--multiple .choices__item{
    background-color: $gray-300;
    border: 1px solid $gray-300;
    border-radius: $border-radius;
    color: $input-color;
  }
  .choices__list--dropdown{
    background: $white;
    border: $input-border-width solid $input-border-color;
    z-index: 9;
    .choices__item--selectable.is-highlighted{
      background-color: $gray-200;
    }
  }
  .choices__list--dropdown .choices__item{
    color: $gray-600;
  }
  .choices__input{
    background: transparent;
  }

}
.choices__list--single {
  padding: 0;
}

.choices[data-type*=select-multiple] .choices__button, .choices[data-type*=text] .choices__button{
  border-left: 1px solid $gray-500;
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjEiIGhlaWdodD0iMjEiIHZpZXdCb3g9IjAgMCAyMSAyMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48ZyBmaWxsPSIjMDAwIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPjxwYXRoIGQ9Ik0yLjU5Mi4wNDRsMTguMzY0IDE4LjM2NC0yLjU0OCAyLjU0OEwuMDQ0IDIuNTkyeiIvPjxwYXRoIGQ9Ik0wIDE4LjM2NEwxOC4zNjQgMGwyLjU0OCAyLjU0OEwyLjU0OCAyMC45MTJ6Ii8+PC9nPjwvc3ZnPg==);
}

.choices[data-type*=select-one]:after {
  content: "";
  height: 10px;
  width: 10px;
  border: none;
  border-width: 5px;
  position: absolute;
  right: 11.5px;
  top: 50%;
  transform: translateY(-50%);
  margin-top: 0;
  background-image: escape-svg($form-select-indicator);
  background-repeat: no-repeat;
  background-position: center center;
}
.choices[data-type*=select-one].is-open:after {
  margin-top: 0;
}

// Content none
.choices__list--dropdown .choices__item--selectable:after{
  content: none;
}
.choices__list--dropdown .choices__item--selectable{
  padding-right: 0;
}
.choices__list--dropdown .choices__item{
  padding: 4px 10px;
}
.choices[data-type*=select-one] .choices__input {
  padding: 6px 10px;
}

//Style for hover
.choices .choices__list--dropdown .choices__item--selectable.is-highlighted {
  background-color: rgba($primary, 0.2);
  color: $primary;
}

//Bottomborder select
.bottomborder-select .choices .choices__inner {
  background-color: transparent;
  color: #414953;
  border: none;
  line-height: 1.6;
  border-radius: 0;
  min-height: auto;
  border-bottom: 1px solid $gray-400;
}

//Style for collaps body
.collapsing .choices__list--dropdown{
  display: none;
}
.collapse.show .choices__list--dropdown{
  display: block;
}
// Style for border radius
.is-flipped.is-open .choices__inner{
  border-radius: 0 0 $border-radius $border-radius;
}
.choices__list--dropdown .choices__list {
  position: relative;
  max-height: 200px;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  will-change: scroll-position;
}
// RTL
  html[dir="rtl"] .choices, 
  html[dir="rtl"] select option,
  .choices__input {
    direction: rtl;
    unicode-bidi: bidi-override;
    text-align: left;
}
